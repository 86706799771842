<template>
<section class='home_list_preload'>
   <div class="swiper-container">
      <div class="swiper-wrapper">
         <div
            class="swiper-slide"
            v-for="item in homeNewsList"
            :key="item.id"
            @mouseup="toNewsPage(item.title,item.id)"
         >
            <div class="box">
               <img :src="picServeURL+item.image" alt="">
               <div class="mask-b">
                  <span class="title">{{ item.title }}</span>
                  <i :style="`background-image: url(${picServeURL + item.image});`"></i>
                  <div class="enter" @mouseup="toNewsPage(item.title,item.id)">
                     查看新闻
                     <span class="iconfont iconhome_more"></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
export default {
   data() {
      return {
         picServeURL: Global.Production,
      }
   },
   props: ["homeNewsList"],
   methods: {
      toNewsPage(title, id) {
         this.$router.push({
            name: "NewsPage",
            params: {
               id
            },
         });
      },
      swiperDemo() {
         var swiper = new Swiper('.swiper-container', {
            autoplay: false,
            allowTouchMove: true,
            preloadImages: false,
            centeredSlides: false,
            spaceBetween: 16,
            loop: true,
            slidesPerView: 'auto',
         });
         window.onresize = function () {
            swiper.update();
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.home_list_preload {
   -moz-user-select: none;
   -webkit-user-select: none;
   user-select: none;
   position: relative;

   .swiper-container {
      overflow: visible;

      .swiper-wrapper {
         width: 100%;
         height: 212px;

         .swiper-slide {
            width: 213px;
            height: 212px;
            overflow: hidden;
            position: relative;
            transition: all 100ms linear;

            .box {
               width: 100%;
               height: 100%;

               img {
                  height: 100%;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
               }

               .mask-b {
                  width: 100%;
                  height: 78px;
                  position: absolute;
                  bottom: 0;
                  z-index: 0;
                  color: #fff;
                  overflow: hidden;
                  padding: 12px;
                  box-sizing: border-box;

                  &>i {
                     content: '';
                     width: 120%;
                     height: 120%;
                     position: absolute;
                     left: 50%;
                     top: 50%;
                     transform: translate(-50%, -50%);
                     z-index: -1;
                     background-position: center bottom;
                     background-size: cover;
                     -webkit-filter: blur(12px);
                     -moz-filter: blur(12px);
                     -ms-filter: blur(12px);
                     -o-filter: blur(12px);
                     filter: blur(12px);
                  }

                  .title {

                     display: inline-block;
                     box-sizing: border-box;
                     white-space: normal;
                     overflow: hidden;
                     text-overflow: ellipsis;
                     -webkit-line-clamp: 2;
                     /* 设置超出多少行隐藏 */
                     -webkit-box-orient: vertical;
                     display: -webkit-box;

                     width: 189px;
                     height: 36px;
                     font-size: 12px;
                     font-family:   PingFangSC-Medium, PingFang SC;
                     font-weight: 500;
                     color: #FFFFFF;
                     line-height: 18px;
                  }

                  .enter {
                     position: absolute;
                     bottom: 12px;
                     width: 100px;
                     font-size: 10px;
                     transform-origin: left bottom;
                     transform: scale(.8);
                     font-family:   PingFangSC-Regular, PingFang SC;
                     height: 12px;
                     line-height: 12px;

                     .iconfont {
                        position: relative;
                        display: inline-block;
                        height: 12px;
                        line-height: 12px;
                        vertical-align: top;
                        transition: all 100ms linear;
                        vertical-align: top;
                        font-size: 10px;
                     }
                  }
               }

            }
         }
      }
   }

}
</style>
